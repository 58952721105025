@use 'src/components/shared/layout';
@use 'src/components/main/page-content';

$table-width: layout.$full-page-width;
$table-height: calc(layout.$full-page-height - page-content.$header-height);

.page-content-metadata {
  position: absolute;
  @include layout.exact-content-dimensions();

  display: flex;
  flex-direction: column;

  .table-container {
    @include layout.exact-dimensions($table-width, $table-height);
    overflow: auto;
  }
}
