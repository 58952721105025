.folia-syntax-guide {
  padding: 0.5rem;

  &-section {
    &-header {
      font-size: 1.5rem;
    }

    &-content {
      line-height: 1.75rem;

      > ul {
        padding: 0;
        list-style-type: none;

        > li {
          margin-bottom: 1.25rem;
        }

        ul {
          list-style-type: none;
        }
      }
    }
  }
}

.markup {
  &-code {
    margin: 0 2px;
    padding: 1px 5px 3px;
    border-radius: 6px;
    background-color: #e4e4e4;
    font-family: Monospace;
    font-size: 85%;
    color: #4c4c4c;
  }

  &-highlight {
    font-weight: 500;
    color: #fa278e;
  }

  &-strong {
    font-weight: bold;
  }
}
