.set-not-checkbox {
  margin-left: 0;
  margin-bottom: 0;
  transform: scale(0.9);

  .MuiFormControlLabel-label {
    margin-left: 8px;
  }

  .MuiCheckbox-root {
    padding: 0;
  }
}
