$border-color: #e6e8e9;
$badge-size: 0.85rem;
$badge-color: #f34440;

.folia-editor {
  border: 1px solid $border-color;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;

  &-sidebar {
    flex-basis: 2rem;
    flex-grow: 0;
    flex-shrink: 0;

    margin: 0.25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border: 0 solid rgb(230, 232, 233);
    border-right-width: 1px;

    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .MuiIconButton-root {
      padding: 8px;
    }

    .validation-legend-anchor {
      margin: 4px;
    }
  }

  &-container {
    flex-grow: 1;
    padding: 0.5rem;
  }

  &-fab {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    &-badge .MuiBadge-dot {
      height: $badge-size;
      min-width: $badge-size;
      border-radius: calc($badge-size / 2);
      background-color: $badge-color;
    }
  }
}
