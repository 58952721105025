.overflow-icon-menu {
  padding: 0;

  &.visible {
    opacity: 1;
  }

  &.dimmed {
    opacity: 0.3;
  }

  &.hidden {
    visibility: hidden;
  }

  &:hover {
    opacity: 1;
  }
}
