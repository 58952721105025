.remote-console {
  padding-top: 112px;
  padding-bottom: 56px;

  .remote-console-category {
    padding: 0 10px;
    font-weight: bold;
  }

  .remote-console-data{
    display: flex;
    flex-direction: row;

    .MuiIconButton-root{
      padding: 1px;
    }
    
    // fixes styling bug with checkboxes, react-new-window doesn't transfer styles correctly (not sure why yet)
    input {
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
    }

    .arc-table-header {
      .label {
        margin: 0 !important;
      }
    }
  
    .arc-table-row {
      height: 20px;
    }
  }
}

.remote-console-dump-header{
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    padding-left: 1em;
    background-color: white;

    .remote-console-dump-header-status{
      display: flex;
      flex-direction: row;
    }
  }