$color-legend: #777;
$color-pristine: #999;
$color-waiting: #f7ba63;
$color-in-progress: #51b2af;
$color-valid: #accf6a;
$color-invalid: #f34440;

.validation-legend {
  &-popover {
    pointer-events: none;

    &-content {
      padding: 0.25rem;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .validation-icon {
        margin: 0.25rem;
      }
    }
  }

  &-item {
    margin: 0 0.25rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-label {
      margin: 0 0.5rem 0 0.2rem;
    }
  }
}

.validation-icon {
  font-size: 1.65rem;

  &.legend {
    color: $color-legend;
  }

  &.pristine {
    color: $color-pristine;
  }

  &.waiting {
    color: $color-waiting;
  }

  &.in-progress {
    color: $color-in-progress;
  }

  &.valid {
    color: $color-valid;
  }

  &.invalid {
    color: $color-invalid;
  }
}
