.login {
  .MuiInputBase-root > fieldset {
    border-color: #fff;
  }

  .MuiFormHelperText-root {
    background-color: #f7f5f7;
    margin: 0;
  }

  .login-button {
    height: 50px;
    width: 300px;
  }
}
