.remote-console-header {
  position: fixed;
  background: linear-gradient(to right, #0077c8 0%, #225165 100%);
  width: 100%;
  height: 56px;
  z-index: 999;
  display: flex;
  align-items: center;

  .algo-logo {
    margin-left: 1em;
    color: white;
  }
}

.remote-console-toolbar{
  position: fixed;
  z-index: 999;
  top: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding-left: 1em;
  background-color: white;

  .remote-console-toolbar-buttons{
    display: flex;
    flex-direction: row;
  }

}