$icon-color: #262626;
$button-color: #fafcfd;
$border-color: #e6e8e9;

.metadata-header {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  &-title {
    margin: 0 1rem;
  }

  &-sandbox-chip {
    margin: 0 1rem;
  }

  &-spacer {
    flex: 1;
  }

  &-button {
    background-color: $button-color;
    border: 1px solid $border-color;

    .metadata-header-icon {
      margin: 14px;
      color: $icon-color;
    }
  }
}
