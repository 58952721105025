$nav-rail-width: 56px;
$top-bar-height: 56px;

$full-page-height: calc(100vh - $top-bar-height);
$full-page-width: calc(100vw - $nav-rail-width);

@mixin exact-width($width) {
  min-width: $width;
  width: $width;
  max-width: $width;
}

@mixin exact-height($height) {
  min-height: $height;
  height: $height;
  max-height: $height;
}

@mixin exact-dimensions($width, $height) {
  @include exact-width($width);
  @include exact-height($height);
}

@mixin exact-content-width {
  @include exact-width($full-page-width);
}

@mixin exact-content-height {
  @include exact-height($full-page-height);
}

@mixin exact-content-dimensions {
  @include exact-content-width();
  @include exact-content-height();
}
