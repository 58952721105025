main {
  display: block;
}

/* Soure-Sans-Pro-Regular */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    /* Super Modern Browsers */
      url('./assets/fonts/SourceSansProRegular/source-sans-pro-v14-latin-regular.woff2')
      format('woff2'),
    /* Modern Browsers */
      url('./assets/fonts/SourceSansProRegular/source-sans-pro-v14-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/SourceSansProRegular/source-sans-pro-v14-latin-regular.svg') format('svg'); /* Legacy iOS */
}

/* Source-Sans-Pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    /* Super Modern Browsers */
      url('./assets/fonts/SourceSansProSemiBold/source-sans-pro-v14-latin-600.woff2')
      format('woff2'),
    /* Modern Browsers */
      url('./assets/fonts/SourceSansProSemiBold/source-sans-pro-v14-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/SourceSansProSemiBold/source-sans-pro-v14-latin-600.svg') format('svg'); /* Legacy iOS */
}

// prevent weird blue outline on focusable components in Chrome and Firefox
div:focus,
a:focus,
button:focus,
input:focus,
td:focus,
textarea:focus {
  outline: none;
}

body {
  line-height: 1;
  background-color: #f7f5f7;
  color: #000;
  font-size: 16px;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
  display: none;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.rdg-selected {
  border: 2px solid #006ca3 !important;

  .drag-handle {
    background: #006ca3 !important;
  }
}

.bx--skeleton__placeholder {
  width: 100%;
  height: 15px;
}

.dx-placeholder,
.dx-texteditor-input,
.dx-texteditor-input-container input,
.dx-treeview-item-content,
.dx-treelist-text-content {
  font: 400 14px 'Source Sans Pro';
}
.dx-treeview-search input {
  font: 400 12px 'Source Sans Pro';
  ::placeholder {
    font: 400 12px 'Source Sans Pro';
  }
}
.dx-empty-message {
  margin-top: 15px;
  text-align: center;
  font: 400 14px 'Source Sans Pro';
}

.MuiAutocomplete-paper {
  width: 400px;
}

.right-drawer > .MuiDrawer-paper {
  border-top-left-radius: 16px;
  overflow-y: hidden;

  .content-container {
    // Container of both accordions fills available vertical space
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    > .Mui-expanded {
      // The expanded accordion fills available vertical space
      flex: 1;
      box-shadow: none;
    }

    .MuiAccordionSummary-root {
      min-height: 32px;
    }

    .MuiAccordionSummary-expandIcon {
      padding: 0 12px;
    }
  }

  .no-date-context {
    margin: 1em;
  }

  .search-field {
    padding: 6px 16px;

    svg {
      height: 15px;
      width: 15px;
    }

    .MuiInputBase-input {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  // Most of this is to make the right things scroll inside the accordions
  .MuiAccordion-root {
    min-height: 0;
    display: flex;
    flex-direction: column;

    // SD-2269 MUI does not consistently put the same class names on this div. It does seem to put
    // at least one of these three.
    .MuiCollapse-root,
    .MuiCollapse-entered,
    .MuiCollapse-container {
      // This div and several descendants each have a single child. "display: flex" with the
      // implied defaults "flex-direction: row" and "align-items: stretch" is a handy way of making
      // these divs fill the vertical space.
      display: flex;

      .MuiCollapse-wrapper {
        flex: 1;
        display: flex;

        .MuiCollapse-wrapperInner {
          display: flex;

          #panel1a-content {
            flex: 1;
            display: flex;

            .portfolio-tree {
              display: flex;
              flex-direction: column;

              .top-controls {
                display: flex;
                align-items: center;

                .MuiFormControlLabel-root {
                  margin: 0;
                }
              }
            }

            .scrolling-container-for-dx {
              border-top: 1px solid #eee;
              border-bottom: 1px solid #eee;
            }

            .MuiAccordionDetails-root {
              padding: 0;
            }

            .MuiAccordionDetails-root.flat-list-portfolios {
              flex: 1;
              flex-direction: column;

              .search-controls {
                display: flex;
                align-items: center;

                .MuiTextField-root {
                  flex: 1;
                }
              }

              .portfolio-flat-list {
                flex: 1;
                overflow-y: auto;
                border-top: 1px solid #eee;

                .portfolio-flat-list-item {
                  align-items: center;
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }

  .MuiAccordion-root.Mui-expanded,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: revert;
  }
}

.scrolling-container-for-dx {
  flex: 1;
  overflow-y: auto;

  .portfolio-item {
    display: flex;
    justify-content: space-between;

    .MuiInputBase-root {
      width: 100px;
    }
  }
}

.benchmark-portfolio-drawer > .MuiDrawer-paper {
  width: 520px;
  border-top-left-radius: 16px;

  .content-container {
    flex-grow: 1;
    overflow: auto;
  }
}

.left-drawer > .MuiDrawer-paper {
  border-top-right-radius: 16px;
}

#folder-drawer .dx-treelist .dx-row > td {
  padding-top: revert;
  padding-bottom: revert;
}

.workspace-tab-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  justify-items: left;
  white-space: nowrap;
}

.workspace-tab-button-root {
  padding-right: 0;
}

.default-workspace-bookmark {
  color: #f1b434;
}

/* Include Benchmarks and Include Differences */

.benchdiff-label {
  font-size: 12px;
}

.benchdiff-root {
  margin: 0;
}

.benchdiff-checkbox {
  padding: 9px 3px 9px 0;
}

/* End Include Benchmarks and Include Differences */

.dx-treelist-content button.btn {
  padding: 0;
  line-height: normal;
}

/* Dropdown overrides */

.dx-popup-flex-height[style] .dx-popup-content {
  max-height: inherit !important;
  min-height: inherit !important;
}

.draggable-filters {
  .checkbox-filter {
    .tree-dropdown-textfield {
      margin: 8px 0;
      height: 26px;
      padding: 0;
    }
  }
}

.stadium-app-bar {
  z-index: 1300;
  background: linear-gradient(to right, #0077c8 0%, #225165 100%);
}

/* TextFields */
fieldset {
  border-radius: 4px !important;
}

.fixed-panel-wrapper {
  overflow: hidden;
  .fixed-panel {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
  }
  .loading-member-panel {
    height: 42px !important;
    width: 42px !important;
  }
  .fixed-panel-header {
    display: grid;
    align-items: center;
    height: 48px;
    padding-left: 12px;
  }
  .fixed-scrollable-region {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    position: relative;
  }
  .sticky-th {
    position: sticky;
    padding: 0;
    margin: 0;
    top: 0;
    background: rgba(0, 103, 127, 0.08);
    z-index: 99;
  }
}

// TODO Determine if we actually want these styles applied to all ths, labels,
// tables, etc., in the whole app
th {
  text-align: inherit; // 2
  text-align: -webkit-match-parent; // 3
}

label {
  // Allow labels to use `margin` for spacing.
  display: inline-block;
  margin-bottom: 0.5rem;
}

table {
  border-collapse: collapse;
}
hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.recharts-default-legend {
  //prevents scrollbars showing up on recharts
  border: 1px transparent solid;
}

// SD-1540, later fixed by SD-1907, then fixed again by SD-2101
.dx-grid-container {
  .MuiTableRow-head:not(:last-child) {
    th {
      padding: 3px 7px;
    }
  }
}
