.groups-tabs {
  grid-area: tabs;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .tab-body {
    flex: 1;
    overflow-y: auto;
  }
}
