$border-color: #e6e8e9;

.custom-grouping-info-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  padding: 1rem;
  border-bottom: 1px solid $border-color;
}
