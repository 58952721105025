.bottom-tag-row {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .MuiButtonBase-root.Mui-checked {
    color: #9ef0f0;
  }
}

// This makes the background transparent for all "interactive" tooltips. Does not apply to most
// tooltips in the app. There's not a more specific way to put styles on one specific tooltip.
.MuiTooltip-popperInteractive > .MuiTooltip-tooltip {
  background: transparent;
}
