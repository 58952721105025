.gsd-predicate {
  &-folia-editor {
    flex: 1;
    width: 100%;
  }
  &-title {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}
