.user-info-panel {
  grid-area: user;
  display: flex;
  flex-direction: column;

  #user-info-body {
    flex: 1;
    padding: 0 16px;

    .MuiFormLabel-root,
    .MuiInputBase-root {
      margin-top: 16px;
    }
  }
}
