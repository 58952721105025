.gsd-characteristic {
  min-height: 0;
  padding: 1rem;

  .breakpoints-paper {
    flex: 1;
    min-height: 0;
  }

  &-dropdown {
    width: 100%;
    margin-bottom: 1rem;
  }
}
