.rconsole-bar-container {
  position: fixed;
  bottom: 0px;
  height: 56px;
  width: 100%;
  z-index: 999;
  background-color: white;

  .MuiLinearProgress-bar1Determinate{
    transition: transform 1s linear 0s;
  }
  
  .chip {
    background-color: #ffd7d9;
    margin-left: 10px;
    padding: 10px;
  }

  .rconsole-bar-info-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .rconsole-bar-settings {
      display: flex;
      align-items: center;
    }
  }
}
