.position-drawer > .MuiDrawer-paper {
  #panel1a-content {
    // Center circular progress
    justify-content: center;
  }

  .content-container > .MuiCircularProgress-root {
    margin-top: 3em;
    align-self: center;
  }

  .benchmark-accordion-details {
    flex: 1;
    flex-direction: column;

    .benchmark-list {
      overflow-y: auto;

      .benchmark-item {
        padding: 7px;
        cursor: default;

        .MuiTypography-root {
          padding-left: 25px;
        }

        &-selected {
          background-color: #e6e6e6;
        }
      }
    }
  }

  .proxy-selector {
    margin: 1em 1em 1.5em 1em;
  }

  .add-instruments {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .position-instrument-item-list {
      overflow-y: auto;
    }
  }

  .footer {
    height: 48px;
    min-height: 48px;
    border-top: 1px solid #e6e8e9;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
