@use 'src/components/shared/layout';

.admin {
  position: absolute;
  @include layout.exact-content-height;

  display: flex;
  flex-direction: column;

  .tabs-bar {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox-container {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-border-table {
    border-right: 1px solid #ddd;
  }

  hr.divider {
    grid-area: divider;
    width: 95%;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .assigned-groups-panel {
    .MuiFormGroup-root {
      padding-left: 1em;
    }
  }

  .component-permissions {
    .MuiFormControlLabel-label {
      padding-left: 7px;
    }
  }
}

.admin-drawer-content {
  flex: 1;
  padding: 1em;
}
