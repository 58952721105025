.ascript-help-container{
  .ascript-help-left{
    position: fixed;
    width: 13%;
    padding-left: .5%;
    padding-left: .5%;
    margin-right: 1%;
    height: 100%;
  }

  .ascript-help-right{
    display: flex;
    padding-left: 15%;
    flex-direction: column;
  }
}