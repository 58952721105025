.entity-selection-drawer {
  .content {
    flex: 1;
    overflow-y: auto;
  }

  div[role='region'] {
    width: 100%;
  }

  .item-text,
  .item-label > .MuiTypography-body1 {
    color: #555555;
  }

  .counterparty {
    width: 100%;

    .none-selected {
      margin-top: 1em;
      text-align: center;
    }

    .selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .list-item {
      border-bottom: solid 1px #ddd;
    }
  }

  .own-entity {
    width: 100%;

    .MuiTextField-root {
      margin-bottom: 1em;
    }

    .MuiCheckbox-root {
      padding: 0 5px 0 9px;
    }
  }
}

.counterparty-popover {
  .MuiPopover-paper {
    width: 336px;
    padding: 1em;
  }

  .dx-treelist-text-content {
    font-size: 12px;
  }

  .no-matches {
    margin: 2em 0;
    text-align: center;
    color: #999;
  }
}
