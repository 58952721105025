.breakpoints-paper {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;

  .breakpoints-header {
    background: rgba(0, 103, 127, 0.05);
    color: rgba(0, 0, 0, 0.65);
    padding: 5px 12px;
  }

  .breakpoints-list {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .breakpoints-list-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding: 0;

      input {
        flex: 1;
        border: none;
        margin-left: 10px;
        font-family: 'Source Sans Pro';
      }

      .MuiButton-label {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
      }
    }
  }
}

button {
  border-radius: 4px;
}
