.portfolio-selection-controls {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &-row {
    > .MuiFormControlLabel-root {
      margin: 0;
    }
  }
}
