.benchmarks-panel {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px;

  .MuiToggleButtonGroup-root {
    .MuiButtonBase-root {
      padding: 3px 15px;
      color: rgba(0, 0, 0, 0.85);
    }

    .MuiButtonBase-root.Mui-selected {
      background: rgba(0, 103, 127, 0.2);
      color: #00677f;
      font-weight: bold;
    }

    :nth-child(1) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    :nth-child(2) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .MuiToggleButton-label {
      font-size: 12px;
    }
  }

  .radio-select {
    display: flex;
    gap: 30px;

    // Prevent width blowout when a value with many characters is selected
    max-width: 100%;

    .dx-placeholder {
      color: black;
    }

    > div:nth-of-type(1) {
      width: 6em;
    }

    > div:nth-of-type(2) {
      flex: 1;
    }
  }
}
