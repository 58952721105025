#folder-drawer {
  .dx-treelist {
    flex-grow: 1;
    min-height: 0;
  }

  .MuiTextField-root {
    margin: 10px 58px;
  }
}
