.scenarios-panel {
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
  padding: 0 12px;

  .tree-dropdown-textfield {
    padding-top: 6px;
  }
}
