.sd-sandbox-chip {
  position: relative;
  display: inline-block;
  background: #d9c89e;
  border-radius: 16px;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 4px 12px;
  max-height: 1.5rem;
  justify-content: flex-start;

  &-action {
    margin-right: -6px;
  }

  &-close {
    transform: scale(0.75);
  }

  &-action:hover {
    cursor: pointer;
  }

  .label {
    max-width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.rename-sandbox-modal {
  .MuiDialog-paper {
    min-width: 308px;

    .MuiDialogContent-root * {
      width: 100%;
    }
  }
}

.sandbox-icon {
  width: 16px;
  margin-right: 4px;
  margin-top: -2px;
}
