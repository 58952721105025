.proxy-selector {
  margin-bottom: 1.5em;

  h3 {
    margin-bottom: 6px;
  }

  svg {
    color: rgba(0, 0, 0, 0.65);
  }
}
