.home-settings {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.home-settings-label {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #555555;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.home-settings-content {
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.home-setting {
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-label {
    flex: 0 0 50%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin: 0.75rem 0;
  }
}
