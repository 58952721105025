.quick-action-group {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.quick-action-group-label {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #555555;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.quick-action-group-content {
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
