$border-color: #e6e8e9;

.custom-grouping-drawer {
  > .MuiDrawer-paper {
    border-top-left-radius: 16px;
    overflow-y: hidden;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;

    &-split {
      min-height: inherit !important; // Does not work to put this in SplitPane style prop

      .Pane2 {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
