.home-page {
  position: absolute;
  width: calc(100% - 56px);
  height: calc(100% - 56px);
  top: 56px;
  left: 56px;

  background-color: #fafcfc;
}

.home-container {
  padding: 10rem 0 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
}

.home-header {
  .home-header-title {
    font-size: 24px;
    letter-spacing: 0.6px;
    line-height: 24px;
    font-weight: 600;
  }

  .home-header-caption {
    font-size: 14px;
    color: #7b7b7b;
    letter-spacing: 0.4px;
    line-height: 24px;
    font-weight: 400;
  }
}

$rule-overhang: 2.5%;
.home-rule {
  width: 100% + 2 * $rule-overhang;
  margin-left: -$rule-overhang;
  margin-right: -$rule-overhang;
}

.home-content {
  flex-grow: 1;

  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 0 calc(100% / 3);
  }
}
