$border-color: #e6e8e9;
$border: 1px solid $border-color;

.action-bar {
  width: 100%;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  &-border-top {
    border-top: $border;
  }

  &-border-bottom {
    border-bottom: $border;
  }

  &-label {
    flex-grow: 1;
    margin: 0 1rem;
  }

  &-divider {
    border-left: $border;
    height: 80%;
  }

  &-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
}
