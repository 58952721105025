.draggable-filters-menu {
  &-item {
    display: flex;
    justify-content: space-between;
    min-width: 144px;
    border-top: none;
    &:last-of-type {
      border-top: 1px #e6e8e9 solid;
    }
  }
  .MuiPopover-paper {
    padding: 0;
    ul {
      padding-bottom: 0;

      .MuiMenuItem-root {
        padding: 8px;
      }
    }
  }
  .filter-type {
    font-size: 12px;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.45);
    border-bottom: 1px #e6e8e9 solid;
    padding-bottom: 12px;
    display: inline-block;
    outline: none;
    width: 100%;
  }
}
