$borderColor: #e6e8e9;

.toolbar {
  min-height: 0;
  padding: 0;
  background-color: #fff;
  justify-content: space-between;
  width: calc(100% - 56px);
  left: 56px;
  position: fixed;
  z-index: 1299;

  .toolbar-menu-icon {
    background-color: #fafcfd;
    border: 1px solid $borderColor;
  }

  .toolbar-group {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    height: 57px;
  }

  .button-style {
    margin: 14px;
    color: #262626;
  }

  .button-zero-padding {
    padding: 0;
  }

  .workspace-path {
    color: #262626;
    padding-left: 18px;
    padding-right: 18px;
  }

  .general-dropdown {
    color: #000;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    .MuiInputBase-input,
    .MuiIconButton-label {
      color: #000;
    }
    .option-typography {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.45);
      letter-spacing: 0.1px;
    }
  }

  .workspace-toolbar-currency-dropdown {
    border-right: 1px $borderColor solid;

    .currency-autocomplete {
      min-width: 127px;
    }
  }

  .wide-autocomplete-dropdown {
    border-left: 1px $borderColor solid;
    border-right: 1px $borderColor solid;
    .wide-dropdown {
      &-autocomplete {
        .MuiInputBase-input,
        .MuiIconButton-label {
          color: rgb(0, 0, 0);
        }
      }
      &-text-field {
        min-width: 260px;
      }
    }
  }
}
