@use 'src/components/shared/layout';

$header-height: 57px;

.page-content {
  margin: layout.$top-bar-height 0 0 layout.$nav-rail-width;
  background-color: #fff;

  .header {
    height: $header-height;
    flex: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-left: 12px;

    .MuiTypography-h1 {
      flex: 1;
    }

    .button-container {
      align-self: stretch;
      width: 57px;
      background-color: rgba(0, 103, 127, 0.02);
      border-left: 1px solid #eee;
      display: grid;
    }
  }
}
