#report-designer-header {
  grid-area: header;
  gap: 1px;
  border: 1px solid rgb(230, 232, 233);
  display: grid;
  grid: 55px / auto 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
}

.header-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding-left: 12px;
  padding-right: 100px;

  .header-label {
    padding-right: 18px;
  }
}

.header-report-name-container {
  background-color: #fff;
  padding: 8px;

  .header-report-name-field {
    width: 100%;
  }
}

.header-icon-container {
  background-color: #fafcfd;
}

.header-icon-button {
  padding: 0;

  .header-icon {
    margin: 14px;
  }
}
