$border-color: #e6e8e9;

.gsd {
  flex: 2;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  &-content {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
