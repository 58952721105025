.filter-form {
  width: 100%;

  .MuiTextField-root {
    background-color: #fff;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
  }

  input {
    padding: 5px;
  }
}
