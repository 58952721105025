.admin-table {
  .action-icon-button {
    margin-left: 1em;

    .MuiSvgIcon-root {
      margin-right: 5px;
    }
    .enabled {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  table {
    table {
      width: 100%;
    }

    th {
      font-weight: normal;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.65);
    }

    th,
    td {
      height: 32px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 14px;
    }

    tr.selected {
      background-color: #f5f5f5;
    }

    .checkbox {
      width: 44px;

      .MuiButtonBase-root {
        padding: 4px 4px 4px 8px;
      }

      .MuiCircularProgress-root {
        width: 20px;
        margin-left: 10px;
      }
    }
  }
}
