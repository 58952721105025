.min-max-filter-container {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 8px;

  .min-max-filter-middle {
    font-size: 10px;
    text-align: center;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
}
