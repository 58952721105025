.report-error {
  flex-grow: 1;
  word-wrap: break-word;
  background-color: #fcf5f6;
  display: flex;

  .styled-error {
    flex: 1;
    display: flex;
    flex-direction: column;

    .main {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .MuiLink-root {
        cursor: pointer;
      }
    }

    .footer {
      margin-bottom: 1em;
    }
  }

  .unstyled-error {
    overflow-y: auto;
  }
}
