.proxy-drawer > .MuiDrawer-paper {
  .loading-indicator-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: #fff;
    opacity: 0.5;
    backdrop-filter: blur(2px);

    .MuiCircularProgress-root {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .proxy-drawer-content {
    flex: 1;
    padding: 12px;

    .selected-instrument {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 8px;

      .instrument-name {
        flex: 1;
      }

      .MuiTextField-root {
        width: 8ch;
      }
    }
  }

  .proxy-drawer-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #eee;

    button {
      height: 24px;
      margin: 6px 12px 6px 0;
      border-radius: 2px;
    }
  }
}

.proxy-selector-popover > .MuiPopover-paper {
  width: 330px;
  height: 350px;

  .positions {
    display: grid;
    grid-template-columns: 1fr 1fr;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
      padding: 2px 24px;
      cursor: pointer;
    }
  }

  .instrument {
    display: flex;
    margin-left: revert;
    padding-top: 4px;
    margin-bottom: 0;

    .MuiButtonBase-root {
      padding: 0;
      margin: 0 13px;
    }

    .MuiFormControlLabel-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }

  .MuiCircularProgress-root {
    position: absolute;
    top: 45%;
    left: 45%;
  }
}

.proxy-clear-dialog {
  button {
    border-radius: 2px;
  }
}
