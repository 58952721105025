.ad-hoc-portfolio-drawer {
  .item-list {
    flex: 1;
    overflow-y: auto;
    margin-top: 8px;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;

    .MuiIconButton-root {
      margin-left: auto;
      padding: 3px 0 0;
    }
  }

  .ad-hoc-portfolio-drawer-footer {
    height: 48px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .upload-portfolio {
      margin: 10px;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.ad-hoc-portfolio-drawer-dialog {
  .MuiDialog-paper {
    width: 300px;
  }

  .selected-file-name {
    margin-top: 1em;
  }
}
