.position-instrument {
  margin-bottom: 1em;
  padding: 1em 0 1em 1em;
  border-top: 1px solid #eee;

  .header {
    display: flex;

    p {
      flex: 1;
    }

    .MuiSvgIcon-root {
      height: 16px;
    }
  }

  .selectors {
    display: flex;
    gap: 1em;

    .value {
      display: flex;
      align-items: center;

      .MuiFormControl-root {
        flex: 1;
      }

      .percent {
        margin-left: 0.5em;
      }
    }

    .value,
    .type {
      flex: 2;
    }

    .currency {
      flex: 1;
    }
  }
}
