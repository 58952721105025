@use 'sass:color';
$selection-color: #e6f0f2;
$animation-duration: 0.15s;

.grouping-tree-container {
  flex: 3;
  min-height: 0;
  margin: 1rem;
  padding: 0.25rem 0;
  border: 1px solid #999;
  border-radius: 6px;
}

.grouping-tree-empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.grouping-tree {
  .dx-row:not(.dx-row-lines):not(.dx-row-focused) {
    > td {
      padding: 1px 10px;
      border-color: #fff;
      transition: background-color $animation-duration, border-color $animation-duration;
    }

    &.dx-selection > td {
      &,
      &.dx-focused {
        background-color: $selection-color;
        border-color: $selection-color;
      }
    }
  }

  // Row hover state is only styled outside of rename mode
  &:not(.no-hover)
    .dx-row:not(.dx-row-lines):not(.dx-row-focused):not(.dx-freespace-row):hover
    > td {
    border-color: $selection-color;

    .grouping-tree-row-label {
      background-color: color.scale($selection-color, $lightness: -15%);
    }
  }

  .dx-treelist-icon-container {
    padding-right: 2px;
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-sizer {
      visibility: hidden;
      width: 0;
    }

    &-label,
    &-rename-field {
      padding: 0 6px;
      margin: 0 2px;
    }

    &-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-radius: 4px;

      &.error {
        text-decoration: #f34440aa wavy underline;
      }
    }
  }

  .dx-checkbox {
    display: none;
  }

  .dx-treelist-focus-overlay {
    border-color: rgba(0, 0, 0, 0);
  }
}
