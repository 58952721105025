.quick-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
}

.quick-action-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin: 0.5rem;
}
