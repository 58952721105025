.characteristic-drawer > .MuiDrawer-paper {
  border-top-left-radius: 16px;

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    .tab-content {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
  }

  .chooser-visibility-container {
    margin-top: 10px;
  }

  .full-width-container {
    margin-bottom: 16px;

    .MuiFormControlLabel-root {
      margin: 0; // This is 16px by default for some reason

      .MuiButtonBase-root {
        padding-top: 0; // These are 9px by default
        padding-bottom: 0;
      }
    }
  }

  .Mui-expanded {
    background-color: #fafcfc;
  }

  .aggregation-input {
    display: flex;
    align-items: center;
    width: 85%;
    margin-right: 16px;
  }

  .side-by-side {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiTextField-root:nth-of-type(1) {
      margin-right: 16px;
    }

    .big {
      flex: 0.75;
    }
    .small {
      flex: 0.25;
    }
  }

  .single-control {
    // Customizations to make this look consistent with the accordions

    label {
      display: flex;
      justify-content: space-between;
      margin: 0px 4px 0px 16px;

      .MuiIconButton-root {
        padding: 12px;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }

  .description-container {
    padding: 16px;
  }
}
