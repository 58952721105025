.edit-time-bucket-modal {
  .MuiDialog-paper {
    width: 574px;
    height: 541px;
    border-radius: 4px 4px 0px 0px;
  }

  .section-header {
    background-color: rgba(0, 0, 0, 0.03);
    font-size: 12px;
    padding: 3px 12px;
  }

  .timestep-groupings-table {
    border-collapse: collapse;
    td {
      border-right: 0.2px solid #e6e8e9;
      display: flex;
      align-items: center;
    }

    tr {
      display: flex;
      align-items: stretch;
      border-bottom: 1px solid #e6e8e9;

      td:nth-child(1) {
        flex: 6;

        input {
          width: 100%;
          font: 400 12px 'Source Sans Pro';
          border: none;
          padding-left: 12px;
        }
      }

      td:nth-child(2) {
        flex: 3;
        padding: 0 3px;
        font-size: 12px;
        color: #0077c8;
        justify-content: space-between;

        .range {
          display: flex;
          gap: 11px;
        }
      }

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  .rounded-corner-button {
    border-radius: 4px;
  }
}
