.nav-rail-app-bar {
  z-index: 1300;
  background-color: white;
  height: 100%;
  width: 57px;
  left: 0;
  display: flex;
  position: fixed;
  transition: top 97.5ms cubic-bezier(0.4, 0, 0.6, 1) 0;
}

$icon-button-margin: 6px;
.nav-rail-container {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 0 solid rgb(230, 232, 233);
  border-right-width: 1px;

  > hr {
    margin: 0 0 $icon-button-margin;
    width: 100%;
  }

  .icon-button {
    height: 48px;
    margin: $icon-button-margin 0;

    &-home {
      margin: 4px 0;
    }
  }
}

.nav-rail-spacer {
  height: 55px;
}
