.MuiDialogContent-root:first-child {
  padding: 0px;
}

.editor-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e6e8e9;
  padding: 5px;

  .editor-class-container {
    margin-left: 10px;
    min-width: 300px;
  }

  .editor-header-buttons {
    border-left: 1px solid #e6e8e9;
  }
}

.editor-dialog-content {
  display: flex;
  flex-direction: column;
  padding: 0px;

  .editor-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e8e9;

    .editor-language-tabs {
      .editor-tab {
        min-width: 100px;
      }
    }

    .editor-copy-button {
      margin-right: 1rem;
    }
  }

  .editor-popups {
    position: absolute;
    width: 100%;
    bottom: 53px;
    overflow: hidden;
    border-top: 1px solid #e6e8e9;

    .editor-error-summary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .editor-popup-text {
      padding-left: 10px;
    }

    .editor-success-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e6e8e9;
      padding-left: 16px;
      padding-right: 5px;
      background-color: white;

      .editor-success-message {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.editor-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .editor-bottom-row-button{
    margin: 6px
  }
}
