.portfolio-drawer {
  .accordion-summary-with-button {
    .MuiAccordionSummary-content {
      justify-content: space-between;
    }
    .rounded-button {
      margin: 0;
      padding: 0;
      height: 18px;
    }
  }
}

