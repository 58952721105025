.nickname-dialog {
  .MuiDialog-paper {
    width: 268px;
  }

  .dialog-title {
    padding: 1em;
    border-bottom: 1px solid #eee;
  }

  .MuiDialogContent-root {
    padding: 24px 14px 14px;
  }
}
