.folia-syntax-guide-anchor {
  position: absolute;
}

.folia-syntax-guide-popover {
  pointer-events: none;

  &-content {
    padding: 1rem;
    width: 700px;
    height: calc(100vh - 34px);
  }

  .MuiPaper-root {
    pointer-events: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 1ex;
      -webkit-border-radius: 1ex;
    }
  }
}
