@use 'src/components/shared/layout';

.top-bar {
  min-height: 0;
  height: layout.$top-bar-height;

  .MuiIconButton-root {
    color: inherit;

    &:nth-of-type(1) {
      margin-left: auto;
    }
  }
}
