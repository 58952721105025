.info-panel-footer-container {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .info-panel-footer-button {
    white-space: nowrap;
    min-width: auto;
  }
}
