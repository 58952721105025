.report {
  .dragHandle {
    cursor: grab;
    transform: rotate(90deg);
    margin-top: 5px;
    vertical-align: top;
    opacity: 0.2;
    display: inline-block;
    &:hover {
      opacity: 1;
    }
  }

  min-height: 0;
  min-width: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 1rem 0 0;

  .margin-horiz {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .report-loading {
    min-height: 0;
    flex: 1;
    display: flex;

    .circular-progress-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-inner {
      flex: 1;
      min-height: 0;
      overflow-y: auto;

      .progress-item {
        margin-top: 1em;

        .progress-label {
          overflow-x: hidden;
          white-space: nowrap;
        }
      }
    }

    .ps {
      margin-left: 1em;
    }
  }
}
