$chip-background: #e6f0f2;

.draggable-element {
  display: flex;
  border-radius: 6px;

  &.on-menu {
    margin: 6px 0px;
  }

  &.blue {
    background: $chip-background;
  }

  &.filter {
    background: $chip-background;
    margin-bottom: 12px;
  }

  &.horizontal {
    margin: auto 3px;
  }

  &.vertical {
    margin: 6px auto;
  }

  &.custom-grouping {
    & .draggable-element-label {
      font-weight: bold;
    }
  }

  .MuiSvgIcon-root {
    cursor: pointer;
    height: 14px;
    width: 15px;
    color: #00677f;
  }
}
